/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
$('body').scrollspy({
    target: '.bs-docs-sidebar',
    offset: 70
});
$("#sidebar").affix({
    offset: {
      top: $('header').outerHeight(true),
      bottom: $('footer').outerHeight(true) + (($('.spacer-bottom').outerHeight(true)*2) + $('#nav').outerHeight(true))
    }
});

       $('#nav').affix({
        offset: {
          top: $('header').outerHeight(true)

        }
      }); 
       $('#nav').on('affix.bs.affix affix-top.bs.affix', function (e) {
        var padding = e.type === 'affix' ? $(this).outerHeight(true) : '';
        $('.spacer-top').css('margin-top', padding);
      });

       $('.navbar-nav>li[data-option]').each(function () {
        $(this).find('li.sub-item').addClass($(this).data('option'));
      });


// Javascript to enable link to tab
var hash = document.location.hash;
var prefix = "tab_";
if (hash) {
    $('.nav-pills a[href='+hash.replace(prefix,"")+']').tab('show');
} 

// Change hash for page-reload
$('.nav-pills a').on('shown', function (e) {
    window.location.hash = e.target.hash.replace("#", "#" + prefix);
});


$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    var target = this.href.split('#');
    $('.nav-stacked  > .active').removeClass('active');
    $('.nav-stacked a[href="#'+target[1]+'"]').parent().addClass('active');
});









},
finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $(".masthead .anchor a[href^='#']").on('click', function(e) {

       // prevent default anchor click behavior
       target = this.hash; 
       e.preventDefault();

       // animate
       $('html, body').animate({
           scrollTop: $(this.hash).offset().top
         }, 300, function(){
   
           // when done, add hash to url
           // (default click behaviour)
           window.location.hash = target;
         });

    });




        var owl = $('#owl-carousel-header');
        var owlvideo = $('#owl-carousel-video-header');
        var owl2 = $('#owl-carousel-events');
        var owl3 = $('.owl-carousel2');
        $(document.documentElement).keyup(function(event) {
                    // handle cursor keys--
                    if (event.keyCode === 37) {
                        owl.trigger('prev.owl'); // go left
                        owl2.trigger('prev.owl'); // go left
                      } else if (event.keyCode === 39) {
                        owl.trigger('next.owl'); // go right
                        owl2.trigger('next.owl'); // go right
                      }

                    });
        owl.owlCarousel({
          items: 1,
          loop: true,
          autoplay:true,
          lazyLoad: true,
          autoHeight: true,
          autoplayTimeout:6000
        });
        owlvideo.owlCarousel({
          items: 1,
          loop: true,
          autoplay:false,
          lazyLoad: true,
          autoHeight: true,
          video:true
        });


        owl2.owlCarousel({
          items: 1,
          loop: true,
          autoplay:true,
          autoplayTimeout:6000,
          autoHeight: true,
          autoplayHoverPause:true,
          animateOut: 'slideOutUp',
          animateIn: 'slideInUp'
        });
        owl3.each(function() {
  var $this = $(this);
  $this.owlCarousel({
        items: 1,
          loop: true,
          autoplay:true,
          autoplayTimeout:6000,
          autoplayHoverPause:true
  });
  // Custom Navigation Events
  $this.parent().find(".next").click(function(){
    $this.trigger('next.owl');
  });
  $this.parent().find(".prev").click(function(){
    $this.trigger('prev.owl');
  });
});

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_exhibition': {
      init: function() {
        // JavaScript to be fired on the about us page
        var owl = $('.owl-carousel');
        owl.owlCarousel({
          items: 1,
          loop: true,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
          lazyLoad: true,
          autoplay:true,
          autoplayTimeout:6000
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
